import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	title: {
		id: 'browser-storage-controls.consent-banner-modal.title',
		defaultMessage: 'Cookie preferences',
		description: 'Cookie preferences modal dialog title.',
	},
	summaryDescription: {
		id: 'browser-storage-controls.consent-banner-modal.summary.description.082624',
		defaultMessage: 'Because we value your privacy, you can select which cookies you allow. {link}',
		description: 'Cookie preferences modal dialog summary description.',
	},
	strictDescription: {
		id: 'browser-storage-controls.consent-banner-modal.strict.description.082624',
		defaultMessage:
			"Atlassian tracks <bold>strictly necessary</bold> cookies that are essential for our products to function, so you can't adjust these. These cookies don't store personally identifiable information or track browsing habits.",
		description: 'Cookie preferences modal strict description.',
	},
	confirmBtn: {
		id: 'browser-storage-controls.consent-banner-modal.confirm.button',
		defaultMessage: 'Confirm',
		description: 'Cookie preferences modal dialog confirm button.',
	},
	cancelBtn: {
		id: 'browser-storage-controls.consent-banner-modal.cancel.button',
		defaultMessage: 'Cancel',
		description: 'Cookie preferences modal dialog cancel button.',
	},
	trackingLink: {
		id: 'browser-storage-controls.consent-banner-modal.tracking.link.082624',
		defaultMessage: 'Review our cookies and tracking notice',
		description: 'Cookie preferences modal link to tracking notice page.',
	},
	functionalOption: {
		id: 'browser-storage-controls.consent-banner-modal.functional.option.082624',
		defaultMessage: 'Allow functional cookies',
		description: 'Cookie preferences modal functional option.',
	},
	functionalOptionDescription: {
		id: 'browser-storage-controls.consent-banner-modal.functional.description.082624',
		defaultMessage: 'Provides enhanced functionality and personalization.',
		description: 'Cookie preferences modal functional option description.',
	},
	analyticalOption: {
		id: 'browser-storage-controls.consent-banner-modal.analytical.option.082624',
		defaultMessage: 'Allow analytical cookies',
		description: 'Cookie preferences modal analytical option.',
	},
	analyticalOptionDescription: {
		id: 'browser-storage-controls.consent-banner-modal.analytical.description.101024',
		defaultMessage: 'Measures usage to improve product performance.',
		description: 'Cookie preferences modal analytical option description.',
	},
	marketingOption: {
		id: 'browser-storage-controls.consent-banner-modal.marketing.option.082624',
		defaultMessage: 'Allow marketing cookies',
		description: 'Cookie preferences modal marketing option.',
	},
	marketingOptionDescription: {
		id: 'browser-storage-controls.consent-banner-modal.marketing.description.082624',
		defaultMessage: 'Used for targeted advertising.',
		description: 'Cookie preferences modal marketing option description.',
	},
});
