/* eslint-disable @atlassian/tangerine/import/no-parent-imports */
import { useEffect, useMemo, useRef, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { getBscGlobalState, sendScreenEvent } from '../../../common/utils';
import { isSSR } from '../../../common/utils/is-ssr';
import {
	ATLASSIAN_BANNER_LAST_DISMISSED_TIME_COOKIE_KEY,
	ATLASSIAN_SHOW_BANNER_COOKIE_KEY,
} from '../../../constants';
import { setStrictlyNecessaryCookie } from '../../../controllers/cookie-controls';
import { getCookie } from '../../../controllers/cookie-controls/get-cookie';
import type { PrefetchedConsentPreferences } from '../../../services/consent-hub-service/types';
import { type ConsentPreference } from '../../../types';
import { shouldShowBannerInSSRContext } from '../utils';

export interface BannerVisibilityHookProps {
	preferences: ConsentPreference | undefined;
	isPrefetchedPreferencesState: boolean;
	initialPreferences?: PrefetchedConsentPreferences;
	isInitialRender: boolean;
}

export const calculateBannerStateViaBit = (
	isInitialRender: boolean,
	initialPreferences?: PrefetchedConsentPreferences,
) => {
	// We don't have a great way to handle dealing with consent timestamps right here, as we have no global state/store, so we have to precompute
	// banner visibility state when we fetch consents, and store it in a cookie to be read later, here.
	const shouldShowBannerBit = getCookie(ATLASSIAN_SHOW_BANNER_COOKIE_KEY);

	const inFlowWhereBannerStateIsUnknown =
		initialPreferences && isInitialRender && shouldShowBannerBit === undefined;

	if (isSSR() || inFlowWhereBannerStateIsUnknown) {
		// When coming from SSR, this hook will only execute once and won't update later
		// Instead, if we're on the server, have initial preference data with valid consents, don't render
		// On later checks (rehydration), this will always fail and skip to the normal rendering logic

		// Once we're rehydrating, if have been provided initial prefs (from SSR), on the "first render", the banner cookie may still be missing.
		// Due to React lifecycle where this useMemo is executing prior to the useEffect in the usePreferences hook, which sets the core cookies.
		// We should give this flow the same treatment as rendering in an SSR context to match the behavior of the SSR flow to reduce any potential UX flicker.

		return shouldShowBannerInSSRContext(initialPreferences);
	}

	const shouldShowBanner = shouldShowBannerBit ? !!Number(shouldShowBannerBit) : false;
	return shouldShowBanner;
};

/**
 * This hook is responsible for determining whether or not the consent banner should be shown based on the user's
 * preferences and the current state of the banner cookie, as well as if the we're rendering from an SSR context.
 *
 * @param preferences - The current state of the user's preferences
 * @param initialPreferences - Any initially provided preferences from consuming applications (used for SSR)
 * @param isInitialRender - A boolean to track if this is the "first" render of the component
 * @param isPrefetchedPreferencesState - A boolean that indicates if are fetching consents internally to our component
 * @returns showBanner - Whether or not the banner should be shown
 * @returns setIsDismissed - Setter function to update the dismissed state of the banner
 */
const useBannerVisibility = ({
	preferences,
	initialPreferences,
	isInitialRender,
	isPrefetchedPreferencesState,
}: BannerVisibilityHookProps) => {
	const isEventSent = useRef(false);
	const [isDismissed, setIsDismissedProp] = useState(false);

	const setIsDismissed = (isDismissed: boolean) => {
		if (fg('platform_moonjelly_banner_dismissal_event')) {
			if (isDismissed) {
				setStrictlyNecessaryCookie(
					ATLASSIAN_BANNER_LAST_DISMISSED_TIME_COOKIE_KEY,
					Date.now().toString(),
					{ path: '/' },
				);
			}
		}
		setIsDismissedProp(isDismissed);
	};

	const showBanner = useMemo(() => {
		if (getBscGlobalState().localConsentMode) {
			// In V1, we only checked based on the existence of the preferences
			const prefsMissing = !preferences;
			if (isSSR()) {
				// This logic will execute normally in V2, but for now, as SSR prefetching has not been implemented completely
				// just hide the banner if we're missing the data (always will be in SSR-context) and let the UI handle it on rehydration
				return !prefsMissing && !isDismissed;
			}
			return prefsMissing && !isDismissed;
		}

		const shouldShowBanner = calculateBannerStateViaBit(isInitialRender, initialPreferences);
		// When preferences are unknown and not flagged as internal (e.g. undefined), show the banner
		const unknownPreferences = !isPrefetchedPreferencesState && !preferences;
		return (unknownPreferences || shouldShowBanner) && !isDismissed;
	}, [isInitialRender, initialPreferences, isPrefetchedPreferencesState, preferences, isDismissed]);

	useEffect(() => {
		if (!isEventSent.current && showBanner) {
			sendScreenEvent({ name: 'cookieConsentBanner' });
			isEventSent.current = true;
		}
	}, [isEventSent, showBanner]);

	return { showBanner, setIsDismissed };
};

export default useBannerVisibility;
