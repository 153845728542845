import { type I18NMessages } from '@atlaskit/intl-messages-provider';

export const fetchMessagesForLocale = async (locale: string): Promise<I18NMessages | undefined> => {
	try {
		const messages = await import(
			/* webpackChunkName: "@atlaskit-internal_browser-storage-controls" */ `../../../i18n/${locale}`
		);
		return messages.default;
	} catch (e) {
		// ignore
	}

	try {
		const parentLocale = locale.split(/[-_]/)[0];
		const messages = await import(
			/* webpackChunkName: "@atlaskit-internal_browser-storage-controls" */ `../../../i18n/${parentLocale}`
		);
		return messages.default;
	} catch (e) {
		// ignore
	}
};
