import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	error: {
		id: 'eoc.common.toast.error',
		defaultMessage: 'Error',
		description: 'The title for an error as a single word.',
	},
	warning: {
		id: 'eoc.common.toast.warning',
		defaultMessage: 'Warning',
		description: 'The title for a warning as a single word.',
	},
	success: {
		id: 'eoc.common.toast.success',
		defaultMessage: 'Success',
		description: 'The title for success as a single word.',
	},
	info: {
		id: 'eoc.common.toast.info',
		defaultMessage: 'Information',
		description: 'The title for an information as a single word.',
	},
});

export default messages;
