import React from 'react';

import { ConsentBanner } from '@atlassian/browser-storage-controls';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

export const ConsentBannerWrapper = ({ ...props }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<div {...ssrPlaceholderIdProp} data-vc="consent-banner-wrapper">
			<ConsentBanner {...props} />
		</div>
	);
};
