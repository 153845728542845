/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'browser-storage-controls.consent-banner-modal.analytical.description.101024':
    'Measures usage to improve product performance.',
  'browser-storage-controls.consent-banner-modal.analytical.option.082624': 'Allow analytical cookies',
  'browser-storage-controls.consent-banner-modal.cancel.button': 'Cancel',
  'browser-storage-controls.consent-banner-modal.confirm.button': 'Confirm',
  'browser-storage-controls.consent-banner-modal.functional.description.082624':
    'Provides enhanced functionality and personalization.',
  'browser-storage-controls.consent-banner-modal.functional.option.082624': 'Allow functional cookies',
  'browser-storage-controls.consent-banner-modal.marketing.description.082624': 'Used for targeted advertising.',
  'browser-storage-controls.consent-banner-modal.marketing.option.082624': 'Allow marketing cookies',
  'browser-storage-controls.consent-banner-modal.strict.description.082624':
    "Atlassian tracks <bold>strictly necessary</bold> cookies that are essential for our products to function, so you can't adjust these. These cookies don't store personally identifiable information or track browsing habits.",
  'browser-storage-controls.consent-banner-modal.summary.description.082624':
    'Because we value your privacy, you can select which cookies you allow. {link}',
  'browser-storage-controls.consent-banner-modal.title': 'Cookie preferences',
  'browser-storage-controls.consent-banner-modal.tracking.link.082624': 'Review our cookies and tracking notice',
  'browser-storage-controls.consent-banner.accept-all.button': 'Accept all',
  'browser-storage-controls.consent-banner.cookies-description.text':
    'Atlassian uses cookies to improve your browsing experience, perform analytics and research, and conduct advertising. Accept all cookies to indicate that you agree to our use of cookies on your device. {linkToCookiesTrackingNotice}',
  'browser-storage-controls.consent-banner.cookies-tracking-notice.text.101024':
    'Atlassian cookies and tracking notice',
  'browser-storage-controls.consent-banner.error-flag.description': 'Try again in a few minutes.',
  'browser-storage-controls.consent-banner.error-flag.title': "We're unable to update the policy",
  'browser-storage-controls.consent-banner.only-necessary.button': 'Only necessary',
  'browser-storage-controls.consent-banner.preferences.button': 'Preferences',
  'browser-storage-controls.consent-banner.success-flag.description':
    'You can change this at any time in your Atlassian account.',
  'browser-storage-controls.consent-banner.success-flag.description-local-only':
    'Your preferences will only affect this site.',
  'browser-storage-controls.consent-banner.success-flag.title': 'Cookie preferences saved',
};
