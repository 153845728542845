import { Logger } from '../../../common/utils';
import { saveConsentPreferences } from '../../../services/consent-hub-service';
import type { ConsentDisplayedTextMap } from '../../../services/consent-hub-service/types';
import {
	checkIfTokenIsDefault,
	StatusCodeError,
} from '../../../services/consent-hub-service/utils';
import {
	type ConsentPreference,
	type ConsentPreferenceV003,
	ContextualConsentProperty,
} from '../../../types';
import {
	setConsentTokenCookie,
	setConsentTokenFallbackCookie,
	setShouldShowBannerCookie,
} from '../set-required-consent-cookies';
import { packUserPreferences } from '../transformer';

import { mergeConsentUpdates, onlySetFallbackIfDifferent } from './utils';

export const createConsentTokenCookies = (
	mergedPrefs: ConsentPreference,
	usingPrefetchedData?: boolean,
) => {
	// On a successful save, transform the prefs object into a formatted consentToken bit string
	// Then store the new token in a 10min-expiry consent cookie and a 1yr-expiry fallback cookie

	const consentToken = packUserPreferences(mergedPrefs);
	// If the token is prefetched and a default, we should rely on the existing cookie data rather than re-setting
	// cookies that are already set
	const tokenIsPrefetchedDefault = usingPrefetchedData && checkIfTokenIsDefault(consentToken);

	if (!tokenIsPrefetchedDefault) {
		if (usingPrefetchedData) {
			// If the fallback token already exists, we don't want to nuke the expiration inadvertently (since this happens on every page load).
			onlySetFallbackIfDifferent(consentToken);
		} else {
			// This has originated from a user saving their consents in the UI, so set a fallback to their new consents immediately.
			setConsentTokenFallbackCookie(consentToken);
		}

		setConsentTokenCookie(consentToken);
		setShouldShowBannerCookie(consentToken);
	}
};

export const createErrorDrivenTokens = (
	err: Error | StatusCodeError,
	mergedPrefs: ConsentPreference,
) => {
	const errorDueToUnauthenticatedRequest = err instanceof StatusCodeError && err.status === 401;
	const errorDueToConsentHubBeingInaccessible =
		err instanceof StatusCodeError && err.status >= 500 && err.status < 600; // Any 5xx error

	// Indicates the user is genuinely logged out instead of ConsentHub being inaccessible
	// If we dont explicitly 401, defer to the previous auth state
	const updatedAuthState = errorDueToUnauthenticatedRequest
		? !errorDueToUnauthenticatedRequest
		: !!(mergedPrefs as ConsentPreferenceV003)[ContextualConsentProperty.UserIsAuthenticated];
	const consentToken = packUserPreferences({
		...mergedPrefs,
		[ContextualConsentProperty.UserIsAuthenticated]: updatedAuthState,
		[ContextualConsentProperty.ConsentDataUnavailable]: errorDueToConsentHubBeingInaccessible, // Indicates we were unable to hit ConsentHub when the consents were created
	});

	if (errorDueToUnauthenticatedRequest) {
		// When the failure is derived from a local-only/unauthenticated user, we need to also set the consent token
		// Otherwise, we will never actually adhere to their consents, as they'll continue to receive default every time we attempt to unsuccessfully fetch their prefs

		Logger.error(`User is unauthenticated. ${err.message}`);
		onlySetFallbackIfDifferent(consentToken);

		setConsentTokenCookie(consentToken);
		setShouldShowBannerCookie(consentToken);
		return;
	}

	// When there's an actual failure, we only store data in the fallbackConsentToken cookie
	// This allows us to still track consents when ConsentHub is down > 10 minute expiry
	// But do _not_ set a consentToken cookie so we attempt to re-request from ConsentHub on sequential loads
	// This circumvents an issue of a user thinking they saved consents to CH, but when we request them later,
	// CH has no record of them, and they get shown the banner again
	Logger.error('Token failed to save');
	onlySetFallbackIfDifferent(consentToken);

	setShouldShowBannerCookie(consentToken);
};

/**
  This logic only runs when a user initiates saving their consents **OR** when we've been provided prefetched data
  and we need to store the passed values, otherwise we'd have no way to set the associated cookies for the user

  @param updatedPreferences: This contains the user's current consents, which they are actively trying to update
  @param displayedTextMap: This contains a mapping of the text shown to the user in the UI for each checkbox
    used by ConsentHub for auditing compliance.
  @param usingPrefetchedData (Optional): This flag denotes this function execution has been triggered from prefetched data
    being passed into `initialPreferences` for the first time. We don't want to save this data to ConsentHub,
    as it's either already there or will never get saved (unauthed).
    It means we've been provided data to initialize our system's internal cookies with this pass.
 */
export const setConsentToken = async (
	updatedPreferences: ConsentPreference,
	displayedTextMap: ConsentDisplayedTextMap,
	usingPrefetchedData?: boolean,
) => {
	const mergedPrefs = mergeConsentUpdates(updatedPreferences, usingPrefetchedData);

	try {
		if (!usingPrefetchedData) {
			// Attempts to save the newly updated preferences object
			// Can only be saved when a user is logged in, so this error will be caught if unauthed
			await saveConsentPreferences(mergedPrefs, displayedTextMap);
		}
		createConsentTokenCookies(mergedPrefs, usingPrefetchedData);
	} catch (err: any) {
		// If we fail to save to ConsentHub, it could be due to ConsentHub being down, the user being unauthenticated/local-only,
		// or an exception somewhere in the stack. Ensure we pack that information into the token where applicable.
		createErrorDrivenTokens(err, mergedPrefs);
	}
};
