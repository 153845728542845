import React, { type PropsWithChildren } from 'react';

import { IntlMessagesProvider } from '@atlaskit/intl-messages-provider';

import messages from '../../../i18n/en';

import { fetchMessagesForLocale } from './fetch-messages-for-locale';

export const BSCIntlProvider = ({ children }: PropsWithChildren<{}>) => {
	return (
		<IntlMessagesProvider defaultMessages={messages} loaderFn={fetchMessagesForLocale}>
			{children}
		</IntlMessagesProvider>
	);
};
